import Resumable from "resumablejs";
import {
  STATUS_UPLOADING,
  STATUS_ERROR,
  STATUS_LABELS,
  STATUS_UPLOAD_TIMEOUT,
} from "~/fc/Constants/job-status";
import { EVENT_BUS } from "../constants";
import { formatBytes } from "../helpers";

export default class ResumableUpload {
  constructor(job, item) {
    this.job = job;
    this.item = item;
    this.jobFailed = false;
    this.uploadVariables = {};
    this.resumableProgress = 0;
  }

  setTask(task) {
    this.task = task;
    return this;
  }

  initiate(fileUploadUrl, fileName, fileExt) {
    return new Resumable({
      chunkRetryInterval: 3000, // 3 seconds
      target: fileUploadUrl
        .replace("api/upload", "api/resumable")
        .replace("server", "s"),
      chunkSize: 2 * 1024 * 1024, // Chunk Size 2MB
      forceChunkSize: true,
      prioritizeFirstAndLastChunk: true,
      testChunks: false,
      generateUniqueIdentifier: (file, event) => {
        let relativePath =
          file.webkitRelativePath || file.fileName || file.name || fileName;
        if (!relativePath) {
          throw new Error("No file name found");
        }
        const size = file.size;
        const limit = 40;
        // We don't change anything if length is less than limit
        if (relativePath.length < limit) {
          return (
            size +
            "-" +
            Math.floor(Math.random() * 1000 + 5000) +
            "-" +
            relativePath.replace(/[^0-9a-zA-Z_-]/gim, "") +
            "." +
            fileExt
          );
        } else {
          relativePath = relativePath.replace(/[^0-9a-zA-Z_-]/gim, "");
          return (
            size +
            "-" +
            Math.floor(Math.random() * 1000 + 5000) +
            "-" +
            relativePath.substring(0, limit / 2) +
            "-" +
            relativePath.substring(relativePath.length - limit / 2) +
            "." +
            fileExt
          );
        }
      },
    });
  }

  onUploadProgress(progressEvent) {
    if (progressEvent !== undefined && $nuxt.$store.state.isInternetAvailable) {
      $nuxt.$store.commit("items/setJobProgress", {
        id: this.job.id,
        status: STATUS_UPLOADING,
        statusText: STATUS_LABELS[STATUS_UPLOADING],
        statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_UPLOADING]),
        progress: progressEvent.progress,
      });
    }
  }

  upload() {
    try {
      const fileUploadUrl = this.task.result.form.url;
      const signature = this.task.result.form.parameters.signature;
      const file = this.item.file;
      const fileName = this.item.name;
      const fileExt = this.item.ext;
      const job = this.job;
      const taskId = this.task.id;
      return new Promise((resolve, reject) => {
        let previousProgress = 0;
        const self = this;
        this.resumable = this.initiate(fileUploadUrl, fileName, fileExt);
        this.resumable.addFile(file);
        const onAdd = () => this.resumable.upload();

        $nuxt.$bus.$on(EVENT_BUS.CANCEL_UPLOAD, () => {
          self.resumable.cancel();
          reject({ message: STATUS_LABELS[STATUS_UPLOAD_TIMEOUT] });
        });

        this.resumable.on("fileAdded", onAdd);

        this.resumable.on("fileSuccess", async function (uploadFile, event) {
          self.resumable.on("complete", () => {
            if (this.jobFailed) return;

            resolve({
              id: job.id,
              taskId: taskId,
              fileUploadUrl: fileUploadUrl,
              fileName: fileName || file.fileName || file.name,
              signature: signature,
              identifier: self.resumable.files
                .map((file) => file.uniqueIdentifier)
                .join(","),
            });
          });
        });

        this.resumable.on("progress", (bytesUploaded, bytesTotal) => {
          // if the job is removed or it's in error state, cancel the ongoing upload
          const j = $nuxt.$store.state.items.jobs[job.id];
          if (!j || j.status === STATUS_ERROR) {
            this.jobFailed = true;
            self.resumable.cancel();
            reject({ message: STATUS_LABELS[STATUS_ERROR] });
          }

          if (previousProgress !== self.resumable.progress()) {
            previousProgress = self.resumable.progress();
          }

          if (this.resumableProgress < self.resumable.progress()) {
            const totalUploadBytes = formatBytes(file.size * self.resumable.progress(), 2);
            this.resumableProgress = self.resumable.progress();
            this.onUploadProgress({
              progress: parseInt(Math.round(self.resumable.progress() * 100)),
              uploadedInMB: totalUploadBytes,
              fileSize: formatBytes(file.size, 2),
            });
          }
        });

        // fired for permanentErrors
        this.resumable.on("error", (message, _) => {
          //Message will be empty when unknown error is happenning. like when internet connection not available long time.After lot of try resumeable cancel the uploading if internet is not available.
          //We handle this state using a simple timeout. If every retry got same issue. It will again retry.
          if (message === '') {
            setTimeout(() => {
              this.resumable.upload();
              _.retry();
            }, 5000);
            return;
          }
          return reject({ message });
        });
      });
    } catch (error) { }
  }
}
